module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uketoly","short_name":"starter","start_url":"/","background_color":"#362819","theme_color":"#362819","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f7a57e8ad3e3a9414dd824a8f2b945d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"325745568416296","cookieName":"uketoly-gdpr-facebook-pixel"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TZ9JCWW","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.uketoly.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-gb","ja-gb","ja-jp"],"defaultLanguage":"en-gb","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://www.uketoly.com","i18nextOptions":{"ns":["common"],"defaultNS":"common","interpolation":{"escapeValue":false},"lowerCaseLng":true,"nsSeparator":false},"pages":[{"matchPath":"/legal/:uid","languages":["en-gb"]},{"matchPath":"/casestudy","languages":["en-gb","ja-gb"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
